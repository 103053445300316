export const CLEAR_CONTACT = 'CLEAR_CONTACT';
export const MERGE_CONTACT = 'MERGE_CONTACT';
export const SET_CONTACT = 'SET_CONTACT';

export const CLEAR_LAYOUT = 'CLEAR_LAYOUT';
export const MERGE_LAYOUT = 'MERGE_LAYOUT';
export const SET_LAYOUT = 'SET_LAYOUT';

export const CLEAR_PLOTS = 'CLEAR_PLOTS';
export const MERGE_PLOTS = 'MERGE_PLOTS';
export const SET_PLOTS = 'SET_PLOTS';
