import { CLEAR_PLOTS, MERGE_PLOTS, SET_PLOTS } from '../types';
import * as R from 'ramda';

const initialState = {
    items: [],
    scene: {
        img: '',
    },
    active: false,
    plot: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case CLEAR_PLOTS:
            return R.clone(initialState);
        case MERGE_PLOTS:
            return R.mergeDeepRight(state, action.payload);
        case SET_PLOTS:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}
