import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from 'store/Store';
import { BrowserRouter as Router } from 'react-router-dom';

const App = React.lazy(() => import('./App'));
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <Router>
                <Suspense fallback={<div className='loading' />}>
                    <App />
                </Suspense>
            </Router>
        </PersistGate>
    </Provider>
);
