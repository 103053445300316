import { combineReducers } from 'redux';

import ContactReducer from './reducer/ContactReducer';
import LayoutReducer from './reducer/LayoutReducer';
import PlotsReducer from './reducer/PlotsReducer';

export default combineReducers({
    contact: ContactReducer,
    layout: LayoutReducer,
    plots: PlotsReducer,
});
